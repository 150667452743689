import { createInstance } from 'i18next';
import resourcesToBackend from 'i18next-resources-to-backend';
import { initReactI18next } from 'react-i18next/initReactI18next';

const i18n = {
  defaultLocale: 'nl',
  locales: ['en', 'nl'],
} as const;

export type TLocale = (typeof i18n)['locales'][number];

export const getOptions = (lng = i18n.defaultLocale as string) => {
  return {
    supportedLngs: i18n.locales,
    fallbackLng: i18n.defaultLocale,
    lng,
    fallbackNS: 'translations',
  };
};

const initI18next = async (lng: string) => {
  const i18nInstance = createInstance();
  await i18nInstance
    .use(initReactI18next)
    .use(
      resourcesToBackend(
        (language: string) => import(`../translations/${language}.json`),
      ),
    )
    .init(getOptions(lng));
  return i18nInstance;
};

export const handleUseTranslation = async (
  lng: string,
  options = {} as any,
) => {
  const i18nextInstance = await initI18next(lng);
  return {
    t: i18nextInstance.getFixedT(
      lng,
      '',
      // Array.isArray(ns) ? ns[0] : ns,
      options?.keyPrefix,
    ),
    i18n: i18nextInstance,
  };
};

export default i18n;
