const styles = {
  container: 'flex items-center justify-center !text-white',
  content: 'flex flex-col items-center gap-y-2',

  head: 'flex flex-col items-center mt-24',
  foot: '',

  title:
    'font-display text-8xl sm:text-[12rem] xl:text-[20rem] font-thin text-center pointer-events-none !text-offwhite-500',
  description: 'mt-8 text-3xl font-semibold tracking-display',

  button: {
    container: 'flex flex-row items-center gap-x-4',
    label: 'text-center hover:underline ',
  },
};

export default styles;
