export const toClassName = (...values: (boolean | string | undefined)[]) => {
  return values.filter(Boolean).join(' ');
};

export const handleWait = (n: number) =>
  new Promise((resolve) => setTimeout(resolve, n));

const handleGenerateEndpoint = (config: any) => {
  let result = '/api';

  let options = {};

  if (config?.page !== undefined) {
    options = {
      ...options,
      page: config?.page,
    };
  }

  if (config?.pagesize !== undefined) {
    options = {
      ...options,
      pagesize: config?.pagesize,
    };
  }

  if (config?.sort !== undefined) {
    options = {
      ...options,
      sort: JSON.stringify(config?.sort),
    };
  }

  if (config?.filter !== undefined) {
    options = {
      ...options,
      filter: JSON.stringify(config?.filter),
    };
  }

  if (config?.embed !== undefined) {
    options = {
      ...options,
      embed: JSON.stringify(config?.embed),
    };
  }

  const params = new URLSearchParams({
    ...options,
    ...config.params,
  } as any).toString();

  if (!config.url?.startsWith('/')) {
    throw Error('Endpoints should start with forward a slash.');
  }

  return `${result}${config.url}${params.length > 0 ? '?' + params : ''}`;
};

export const handleGetData = async <T>(config: {
  url: string;
  lang?: string;
  page?: number;
  pagesize?: number;
  sort?: any;
  embed?: any;
  filter?: any;
  params?: any;
}) => {
  const endpoint = handleGenerateEndpoint(config);
  const options = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  const response = await fetch(endpoint, options);
  const result = await response.json();

  if (!response.ok) {
    throw new Error(result);
  }

  return result as { data: T };
};

export const toEuro = (value: number) => {
  if (value == undefined) {
    return '-';
  }

  if (typeof value === 'string') {
    return value;
  }

  return `€ ${(value / 100).toFixed(2)}`;
};
